.teacher-details .teacher-image img {
  width: 100%;
}
.teacher-details .teacher-content {
  margin-bottom: 40px;
}
.teacher-details .teacher-content h4 {
  font-size: 24px;
  line-height: 30px;
  color: #ffb400;
}
.teacher-details .teacher-content > span {
  font-size: 16px;
  line-height: 28px;
  color: #707070;
  margin-bottom: 10px;
  display: inline-block;
}
.teacher-details .teacher-content p {
  line-height: 28px;
  margin-bottom: 20px;
}
.teacher-details .social-default {
  overflow: hidden;
  margin-bottom: 30px;
}
.teacher-details .teacher-address {
  overflow: hidden;
}
.teacher-details .teacher-address li {
  list-style: none;
  margin-bottom: 6px;
  font-size: 16px;
  color: #707070;
}
.teacher-details .teacher-address li span {
  display: inline-block;
  height: 36px;
  width: 36px;
  border: 1px solid #f0f0f0;
  border-radius: 1px;
  text-align: center;
  font-size: 18px;
  line-height: 2;
  color: #ffb400;
  margin-right: 10px;
}
.teacher-details .teacher-statement {
  margin-bottom: 50px;
}
.teacher-details .teacher-statement h4 {
  color: #6c5ebf;
  margin-bottom: 10px;
}
.teacher-details .teacher-statement p {
  font-size: 16px;
  line-height: 26px;
}
.teacher-details .teacher-skills h4 {
  color: #6c5ebf;
  margin-bottom: 30px;
}
.teacher-details .teacher-skills .skill-item {
  display: inline-block;
  margin-right: 44px;
  text-align: center;
}
.teacher-details .teacher-skills .skill-item:last-child {
  margin-right: 0px;
}
.teacher-details .teacher-skills .circle {
  position: relative;
  margin-bottom: 10px;
}
.teacher-details .skill-item strong {
  color: #ffb400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.teacher-details .teacher-skills p {
  font-size: 16px;
  color: #696969;
  margin: 0px;
}
.teacher-details .teacher-award h4 {
  color: #6c5ebf;
  margin-bottom: 20px;
}
.teacher-details .teacher-award li {
  list-style: none;
  display: inline-block;
  margin-right: 60px;
  text-align: center;
}
.teacher-details .teacher-award li:last-child {
  margin: 0px;
}
.teacher-details .teacher-award li img {
  margin: 10px auto 32px;
}
.teacher-details .teacher-award li span {
  font-size: 16px;
  color: #696969;
}
