.about {
  text-align: center;
}
.about h3 {
  font-size: 36px;
  color: #6c5ebf;
  margin-bottom: 10px;
}
.about h4 {
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  color: #ffb400;
  margin-bottom: 15px;
}
.about p {
  margin-bottom: 40px;
  line-height: 30px;
}
.about ul {
  margin: 0px;
  overflow: hidden;
}
.about ul li {
  position: relative;
  display: inline-block;
  list-style: none;
  margin-right: 20px;
}
.about ul li a {
  background-color: #6c5ebf;
}
.about ul li a:hover {
  background-color: #ffb400;
}
.about ul li:last-child {
  margin: 0px;
}
.about-two {
  overflow: hidden;
  background-color: #6c5ebf;
}
.about-two .left {
  width: 50%;
  float: left;
  background-color: #6c5ebf;
  color: #fff;
  padding: 120px 15px;
}
.about-two .left-in {
  float: right;
  max-width: 580px;
}
.about-two .left .title {
  overflow: hidden;
  margin-bottom: 30px;
}
.about-two .left .title .t-left {
  float: left;
  margin-right: 20px;
}
.about-two .left .title .t-right {
  display: table;
}
.about-two .left .title h2 {
  font-size: 100px;
  line-height: 1;
  color: #fff;
}
.about-two .left .title h4 {
  margin-top: 20px;
}
.about-two .left ul {
  margin: 0px;
}
.about-two .left ul li {
  list-style: none;
  margin-bottom: 40px;
  overflow: hidden;
}
.about-two .left ul li:last-child {
  margin: 0px;
}
.about-two .left .icon:before {
  font-size: 40px;
}
.about-two .left .icon {
  float: left;
  margin-right: 30px;
  height: 100px;
  width: 100px;
  border: 1px solid #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 2.5;
}
.about-two .left .content {
  display: table;
}
.about-two .left h4 {
  color: #fff;
}
.about-two .left p {
  color: #fff;
  margin: 0px;
}
.about-two .right {
  width: 50%;
  float: right;
}
.about-two .right img {
  width: 100%;
}
.about-three {
  background-image: url(/assets/images/background/about-bg.jpg);
  background-size: cover;
  background-position: center;
  text-align: left;
}
.about-three .about-content {
  padding: 136px 0px;
}
.about-three .about-image {
  margin-top: 65px;
}
.about-four {
  text-align: left;
}
.about-four ul li {
  list-style: none;
  line-height: 30px;
}
.about-four ul li i {
  font-size: 12px;
  color: #b1b1b1;
  margin-right: 5px;
}
.about-four img {
  width: 100%;
}
.about-four .nav-tabs {
  margin-bottom: 30px;
  border: none;
}
.about-four .nav-tabs > li {
  border-bottom: 1px solid #ebebeb;
  margin: 0px;
  padding-right: 20px;
}
.about-four .nav-tabs > li > a {
  font-weight: 700;
  font-size: 24px;
  color: #6c5ebf;
  background: none;
  border: none;
  padding: 0px;
  margin-bottom: 20px;
  position: relative;
}
.about-four .nav-tabs > li > a:hover,
.about-four .nav-tabs > li.active > a,
.about-four .nav-tabs > li.active > a:focus,
.about-four .nav-tabs > li.active > a:hover {
  border: none;
  background: none;
  color: #ffb400;
}
.about-four .nav-tabs > li.active > a:before {
  content: url(/assets/images/about/menu-icon.jpg);
  position: absolute;
  bottom: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
