.courses .course-item {
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.courses .course-item:hover {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.courses .course-item .image {
  position: relative;
  overflow: hidden;
}
.courses .course-item .image span {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #ffb400;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  width: 100px;
  height: 45px;
  text-align: center;
  line-height: 2;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.courses .course-item .image img {
  width: 100%;
  transition: 0.25s ease-out;
  -webkit-transition: 0.25s ease-out;
}
.courses .course-item:hover .image img {
  transform: scale(1.3);
}
.courses .course-item .content {
  padding: 20px 20px 15px;
}
.courses .course-item .content h4 a {
  text-decoration: none;
  color: #212121;
}
.courses .course-item .content h4 a:hover {
  color: #ffb400;
}
.courses .course-item .content ul li {
  list-style: none;
  display: inline-block;
}
.courses .course-item .content ul li:nth-child(2) {
  color: #f0f0f0;
  margin: 0px 10px;
}
.courses .course-item .content ul li span,
.courses .course-item .content ul li a {
  text-decoration: none;
  color: #6c5ebf;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.courses .course-item .content ul li:hover span,
.courses .course-item .content ul li:hover a {
  color: #ffb400;
}
.courses .course-item .content p {
  margin: 0px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
}
.courses .course-item .bottom {
  overflow: hidden;
  padding-top: 15px;
}
.courses .course-item .bottom ul {
  margin: 0px;
  float: left;
}
.courses .course-item .bottom ul li {
  list-style: none;
  display: inline-block;
}
.courses .course-item .bottom ul li:first-child {
  margin-right: 20px;
}
.courses .course-item .bottom ul li span {
  color: #6c5ebf;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.courses .course-item .bottom ul li:hover span {
  color: #ffb400;
}
.courses .course-item .bottom ul li a {
  text-decoration: none;
  font-weight: 700;
  color: #212121;
}
.courses .course-item .bottom .course-rating {
  float: right;
}
.courses .course-item .bottom .course-rating span {
  color: #ffb400;
}
.courses .courses-button {
  text-align: center;
  margin-top: 30px;
}
.courses .course-item-list {
  margin-bottom: 50px;
}
.courses .course-item-list .image {
  float: left;
}
.courses .course-item-list .image span {
  left: auto;
  right: 0;
  bottom: 20px;
}
.courses .course-item-list .content {
  display: table;
  padding: 25px 25px 16px;
}
.courses .course-item-list .content p {
  line-height: 28px;
  padding-bottom: 20px;
}
.courses .course-item-list .content h4 {
  margin-bottom: 5px;
}
.course-single-item .image {
  margin-bottom: 35px;
}
.course-single-item h3 {
  font-size: 30px;
  color: #212121;
  margin-bottom: 5px;
}
.course-single-item .course-meta {
  margin-bottom: 20px;
}
.course-single-item .course-meta li {
  list-style: none;
  display: inline-block;
  color: #888888;
  margin-right: 12px;
}
.course-single-item .course-meta li i {
  color: #ffb400;
  margin-right: 4px;
}
.course-single-item .course-meta li span {
  color: #b1b1b1;
}
.course-single-item .course-meta li a {
  color: #b1b1b1;
  text-decoration: none;
}
.course-single-item .course-meta li.rating i {
  color: #ffb400;
  margin: 0px;
}
.course-single-item h4 {
  color: #6c5ebf;
  margin-bottom: 5px;
}
.course-single-item .course-features {
  margin-top: 10px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}
.course-single-item .course-features .title {
  text-align: center;
  background-color: #ffb400;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.course-single-item .course-features li {
  list-style: none;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
  color: #696969;
  padding: 10px 25px;
}
.course-single-item .course-features li:first-child,
.course-single-item .course-features li:last-child {
  border: none;
}
.course-single-item .course-features li i {
  color: #ffb400;
  margin-right: 10px;
}
.course-single-item .course-features li span {
  float: right;
}
.course-single-item .course-attachments {
  margin-bottom: 40px;
}
.course-single-item .course-attachments h4 {
  margin-bottom: 15px;
}
.course-single-item .course-attachments li {
  list-style: none;
  border: 1px solid #f0f0f0;
  font-size: 16px;
  color: #696969;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.course-single-item .course-attachments li a {
  color: #696969;
}
.course-single-item .course-attachments li:nth-child(odd) {
  background-color: #fafafa;
}
.course-single-item .course-attachments li i {
  color: #ffb400;
  margin-right: 10px;
}
.course-single-item .course-attachments li:nth-child(even) i {
  color: #6c5ebf;
}
.course-single-item .course-attachments li span {
  float: right;
}
.course-single-item .instructor {
  margin-top: 60px;
}
.course-single-item .instructor h4 {
  margin-bottom: 20px;
}
.course-single-item .instructor .content-bg {
  overflow: hidden;
}
.course-single-item .instructor .image {
  float: left;
  height: 130px;
  width: 130px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
}
.course-single-item .instructor .content {
  display: table;
  padding: 20px 0px;
}
.course-single-item .instructor .content h5 {
  font-size: 20px;
  color: #22313f;
}
.course-single-item .instructor .content span {
  font-size: 14px;
  color: #707070;
}
.course-single-item .instructor .content ul li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
}
.course-single-item .instructor .content ul li a {
  font-size: 20px;
  color: #3b5998;
}
.course-single-item .instructor .content ul li:nth-child(2) a {
  color: #db4437;
}
.course-single-item .instructor .content ul li:nth-child(3) a {
  color: #e94c89;
}
.course-single-item .instructor .content ul li:nth-child(4) a {
  color: #23b6ea;
}
.course-single-item .instructor .content ul li:nth-child(5) a {
  color: #cb1f27;
}
.course-single-item .course-reviews {
  overflow: hidden;
}
.course-single-item .course-reviews .av-rating-bg h5,
.course-single-item .course-reviews .de-rating-bg h5 {
  font-size: 16px;
  color: #212121;
  margin-bottom: 15px;
}
.course-single-item .course-reviews .av-rating-bg {
  float: left;
  width: 23%;
  margin-right: 30px;
  margin-bottom: 40px;
}
.course-single-item .course-reviews .av-rating {
  border: 1px solid #ebebeb;
  border-radius: 3px;
  text-align: center;
  padding: 33px 0px;
}
.course-single-item .course-reviews .av-rating-bg span {
  font-size: 60px;
  line-height: 1;
  font-weight: 700;
  color: #ffb400;
  margin-bottom: 10px;
  display: block;
}
.course-single-item .course-reviews .av-rating-bg .rating i {
  color: #ffb400;
  font-size: 14px;
}
.course-single-item .course-reviews .av-rating-bg p {
  margin: 0px;
}
.course-single-item .course-reviews .de-rating-bg {
  width: 73%;
  float: left;
  margin-bottom: 40px;
}
.course-single-item .course-reviews .de-rating {
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 25px 30px;
}
.course-single-item .course-reviews .de-rating .rat-item {
  overflow: hidden;
}
.course-single-item .course-reviews .de-rating .left {
  font-size: 16px;
  font-weight: 700;
  color: #212121;
  float: left;
  margin-right: 20px;
}
.course-single-item .course-reviews .de-rating .middle {
  width: 80%;
  display: inline-block;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  height: 10px;
}
.course-single-item .course-reviews .de-rating .middle .fill {
  background-color: #ffb400;
  width: 100%;
  height: 8px;
  display: block;
  border-radius: 10px;
}
.course-single-item .course-reviews .de-rating .rat-item:nth-child(2) .fill {
  width: 80%;
}
.course-single-item .course-reviews .de-rating .rat-item:nth-child(3) .fill {
  width: 60%;
}
.course-single-item .course-reviews .de-rating .rat-item:nth-child(4) .fill {
  width: 40%;
}
.course-single-item .course-reviews .de-rating .rat-item:nth-child(5) .fill {
  width: 20%;
}
.course-single-item .course-reviews .de-rating .right {
  float: right;
  color: #696969;
}
.course-single-item .review-item {
  border-bottom: 1px solid #f0f0f0;
  padding: 30px 0px;
}
.course-single-item .review-item:last-child {
  border: none;
}
.course-single-item .review-item .image {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  overflow: hidden;
  float: left;
  margin-right: 20px;
}
.course-single-item .review-item .image img {
  width: 100%;
}
.course-single-item .review-item .content {
  display: table;
}
.course-single-item .review-item .content .title {
  overflow: hidden;
}
.course-single-item .review-item .content h5 {
  font-size: 20px;
  font-weight: 700;
  float: left;
  margin-right: 20px;
  color: #212121;
}
.course-single-item .review-item .content span {
  float: left;
  font-size: 15px;
  line-height: 34px;
  color: #b1b1b1;
}
.course-single-item .review-item .content .course-rating {
  float: right;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.course-single-item .review-item .content .course-rating i {
  color: #ffb400;
  font-size: 14px;
}
.course-single-item .review-item p {
  margin: 0px;
}
.course-single-item .review-form {
  margin-top: 10px;
}
.course-single-item .review-form h4 {
  margin-bottom: 20px;
}
.course-single-item .review-form .form-item {
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  padding: 12px 15px;
  margin-bottom: 30px;
  outline: none;
  font-size: 14px;
  color: #696969;
}
.course-single-item .review-form span {
  float: left;
  margin-right: 20px;
  font-size: 14px;
  color: #696969;
  line-height: 3.4;
}
.course-single-item .review-form .course-rating {
  line-height: 3.3;
}
.course-single-item .review-form .course-rating i {
  color: #ffb400;
  font-size: 14px;
}
.course-single-item .review-form button {
  font-size: 16px;
  font-weight: 700;
  outline: none;
  padding: 18px 26px;
}
