.services .service-item {
  text-align: center;
  background-color: #fff;
  padding: 30px 25px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.services .service-item:hover {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.services .service-item .icon:before {
  font-size: 60px;
  color: #ffb400;
}
.services .service-item h4 {
  color: #212121;
  margin-bottom: 15px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.services .service-item:hover h4 {
  color: #ffb400;
}
.services .service-item p {
  margin-bottom: 15px;
}
.services .service-item a {
  color: #212121;
  font-weight: 700;
  text-decoration: none;
}
.services .service-item a:hover {
  color: #ffb400;
}
.services-two > div > div > div:nth-child(1) .service-item .icon {
  color: #add546;
}
.services-two > div > div > div:nth-child(2) .service-item .icon {
  color: #ffb400;
}
.services-two > div > div > div:nth-child(3) .service-item .icon {
  color: #6cabb8;
}
.services-two > div > div > div:nth-child(4) .service-item .icon {
  color: #6c5ebf;
}
.services-two > div > div > div:nth-child(5) .service-item .icon {
  color: #0fbaf4;
}
.services-two > div > div > div:nth-child(6) .service-item .icon {
  color: #92278f;
}
