.banner {
  position: relative;
}
.banner .banner-slider {
  z-index: 0;
}
.banner .banner-item {
  background-image: url(/assets/images/banner/banner_1.01.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}
.banner .banner-item.slide-two {
  background-image: url(/assets/images/banner/banner_1.02.jpg);
}
.banner .banner-item.slide-three {
  background-image: url(/assets/images/banner/banner_1.03.jpg);
}
.banner .banner-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.banner .banner-item .banner-content {
  padding: 250px 0px;
}
.banner .banner-item .banner-content h3 {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  transition: 0.5s ease-out;
  -webkit-transition: 0.5s ease-out;
  opacity: 1;
}
.banner .banner-item .banner-content h2 {
  display: inline-block;
  color: #fff;
  font-size: 64px;
  line-height: 80px;
  transition: 0.5s ease-out;
  -webkit-transition: 0.5s ease-out;
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  opacity: 1;
}
.banner .banner-item .banner-content p {
  color: #fff;
  max-width: 600px;
  font-size: 36px;
  line-height: 1.5;
  margin-bottom: 30px;
  transition: 0.3s ease-out;
  -webkit-transition: 0.3s ease-out;
  transition-delay: 1s;
  -webkit-transition-delay: 1s;
  opacity: 1;
}
.banner .banner-item .banner-content ul {
  overflow: hidden;
  transition: 0.3s ease-out;
  -webkit-transition: 0.3s ease-out;
  transition-delay: 1.5s;
  -webkit-transition-delay: 1.5s;
  opacity: 1;
}
.banner .banner-item .banner-content ul li {
  list-style: none;
  display: inline-block;
  float: left;
  margin-right: 20px;
}
.banner .banner-item .banner-content ul li:last-child {
  margin: 0px;
}
.banner .banner-item .banner-content ul li a {
  background-color: #fff;
  color: #ffb400;
}
.banner .banner-item .banner-content ul li a:hover {
  background-color: #ffb400;
  color: #fff;
}
.banner .swiper-container-horizontal > .swiper-pagination-bullets,
.banner .swiper-pagination-custom,
.banner .swiper-pagination-fraction {
  bottom: 50px;
}
.banner .swiper-pagination-bullet {
  background: transparent;
  border: 1px solid #fff;
  height: 15px;
  width: 15px;
  opacity: 1;
}
.banner .swiper-pagination-bullet-active {
  background-color: #fff;
}
.banner .banner-item.slide-one .banner-content h3 {
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  opacity: 0;
}
.banner .banner-item.slide-one.swiper-slide-active .banner-content h3 {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  opacity: 1;
}
.banner .banner-item.slide-one .banner-content h2 {
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  opacity: 0;
}
.banner .banner-item.slide-one.swiper-slide-active .banner-content h2 {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  opacity: 1;
}
.banner .banner-item.slide-one .banner-content p {
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  opacity: 0;
}
.banner .banner-item.slide-one.swiper-slide-active .banner-content p {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  opacity: 1;
}
.banner .banner-item.slide-one .banner-content ul {
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  opacity: 0;
}
.banner .banner-item.slide-one.swiper-slide-active .banner-content ul {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  opacity: 1;
}
.banner .banner-item.slide-two .banner-content h3 {
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  opacity: 0;
}
.banner .banner-item.slide-two.swiper-slide-active .banner-content h3 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.banner .banner-item.slide-two .banner-content h2 {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  opacity: 0;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}
.banner .banner-item.slide-two.swiper-slide-active .banner-content h2 {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  opacity: 1;
}
.banner .banner-item.slide-two .banner-content p {
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.banner .banner-item.slide-two.swiper-slide-active .banner-content p {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.banner .banner-item.slide-two .banner-content ul {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
.banner .banner-item.slide-two.swiper-slide-active .banner-content ul {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.banner .banner-item.slide-three .banner-content h3,
.banner .banner-item.slide-three .banner-content h2,
.banner .banner-item.slide-three .banner-content p,
.banner .banner-item.slide-three .banner-content ul {
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  opacity: 0;
}
.banner .banner-item.slide-three.swiper-slide-active .banner-content h3,
.banner .banner-item.slide-three.swiper-slide-active .banner-content h2,
.banner .banner-item.slide-three.swiper-slide-active .banner-content p,
.banner .banner-item.slide-three.swiper-slide-active .banner-content ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.banner-two .banner-item {
  background-image: url(/assets/images/banner/banner_2.01.jpg);
}
.banner-two .banner-item.slide-two {
  background-image: url(/assets/images/banner/banner_2.02.jpg);
}
.banner-two .banner-item.slide-three {
  background-image: url(/assets/images/banner/banner_2.03.jpg);
}
.banner-two .banner-item .banner-content h3 {
  font-weight: 400;
  font-size: 30px;
}
.banner-two .banner-item .banner-content h2 {
  font-size: 48px;
  text-transform: uppercase;
}
.banner-two .banner-item .banner-content p {
  font-weight: 400;
  font-size: 16px;
  margin: 0 auto 30px;
}
.banner-two .banner-item .banner-content {
  text-align: center;
}
.banner-two .banner-item .banner-content ul li {
  float: initial;
}
.banner-two .banner-item .banner-content ul li a {
  background-color: #6c5ebf;
  color: #fff;
}
.banner-three {
  background-image: url(/assets/images/banner/banner_3.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 150px 0px;
}
.banner-three .banner-content {
  float: right;
  max-width: 520px;
  background-color: #fff;
  padding: 30px 40px 40px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}
.banner-three .banner-content h3 {
  color: #ffb400;
  font-size: 36px;
  margin-bottom: 25px;
  line-height: 1;
}
.banner-three .banner-content input {
  width: 100%;
  padding: 14px 15px;
  margin-bottom: 10px;
  border: 1px solid #f0f0f0;
  background-color: #f8f8f8;
  outline: none;
}
.banner-three .banner-content input:focus {
  background-color: #fff;
  border: 1px solid #ffb400;
}
.banner-three .banner-content select {
  width: 49%;
  padding: 14px 15px;
  margin-bottom: 10px;
  border: 1px solid #f0f0f0;
  background-color: #f8f8f8;
  outline: none;
}
.banner-three .banner-content select:nth-child(4),
.banner-three .banner-content select:nth-child(6) {
  float: right;
}
.banner-three .banner-content select:focus {
  background-color: #fff;
  border: 1px solid #ffb400;
}
.banner-three .banner-content button {
  width: 100%;
  padding: 17px 15px;
  margin-top: 20px;
  background-color: #ffb400;
  color: #fff;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.banner-three .banner-content button:hover,
.banner-three .banner-content button:focus {
  background-color: #6c5ebf;
}
.banner-four .banner-item {
  background-image: url(/assets/images/banner/banner_4.01.jpg);
}
.banner-four .banner-item.slide-two {
  background-image: url(/assets/images/banner/banner_4.02.jpg);
}
.banner-four .banner-item.slide-three {
  background-image: url(/assets/images/banner/banner_4.03.jpg);
}
.banner-four .banner-item .banner-content h3 {
  font-style: italic;
  font-weight: 400;
}
.banner-four .banner-item .banner-content p {
  font-weight: 400;
}
.banner-four .banner-item .banner-content ul li a {
  background-color: #6c5ebf;
  color: #fff;
}
.banner-four .swiper-pagination-bullet {
  border: 1px solid #ffb400;
}
.banner-four .swiper-pagination-bullet-active {
  background-color: #ffb400;
}
.banner-five .banner-item {
  background-image: url(/assets/images/banner/banner_5.01.jpg);
}
.banner-five .banner-item.slide-two {
  background-image: url(/assets/images/banner/banner_5.02.jpg);
}
.banner-five .banner-item.slide-three {
  background-image: url(/assets/images/banner/banner_5.03.jpg);
}
.banner-five .banner-item .banner-content h3 {
  font-weight: 400;
  font-size: 30px;
}
.banner-five .banner-item .banner-content h2 {
  font-size: 48px;
  text-transform: uppercase;
}
.banner-five .banner-item .banner-content p {
  font-weight: 400;
  font-size: 16px;
  margin: 0 auto 30px;
}
.banner-five .banner-item .banner-content {
  text-align: center;
}
.banner-five .banner-item .banner-content ul li {
  float: initial;
}
.banner-five .banner-item .banner-content ul li a {
  background-color: #6c5ebf;
  color: #fff;
}
.banner-six {
  background-image: url(/assets/images/banner/banner_6.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  font-family: fa-style-family;
  font-weight: bold;
}
.banner-six .overlay {
  padding: 250px 0px;
  background-color: rgba(0, 0, 0, 0.5);
}
.banner-six .banner-content p {
  color: #fff;
}
.banner-six .banner-content h3 {
  color: #fff;
}
.banner-seven .banner-item {
  background-image: url(/assets/images/banner/banner_7.01.jpg);
}
.banner-seven .banner-item.slide-two {
  background-image: url(/assets/images/banner/banner_7.02.jpg);
}
.banner-seven .banner-item.slide-three {
  background-image: url(/assets/images/banner/banner_7.03.jpg);
}
.banner-seven .banner-item .banner-content {
  text-align: center;
}
.banner-seven .banner-item .banner-content h2 {
  font-size: 60px;
}
.banner-seven .banner-item .banner-content p {
  margin: 0 auto 50px;
}
.banner-seven .banner-item .banner-content ul li {
  float: initial;
}
.banner-seven .banner-item .banner-content ul li a {
  background-color: #6c5ebf;
  color: #fff;
}
.banner-eight {
  background-image: url(/assets/images/banner/banner_8.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}
.banner-eight .overlay {
  background-color: rgba(255, 180, 0, 0.8);
}
.banner-eight .banner-content {
  padding: 180px 15px;
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
}
.banner-eight .banner-content p {
  font-size: 24px;
  line-height: 1.5;
  color: #212121;
  margin: 0px;
}
.banner-eight .banner-content h3 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 25px;
}
.banner-eight .banner-content form {
  position: relative;
  margin-bottom: 30px;
}
.banner-eight .banner-content form input {
  width: 100%;
  padding: 19px 15px;
  border: none;
  outline: none;
}
.banner-eight .banner-content form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 0;
  width: 60px;
}
.banner-eight .banner-content ul {
  margin: 0px;
}
.banner-eight .banner-content ul li {
  list-style: none;
  display: inline-block;
  margin-right: 30px;
  color: #212121;
}
.banner-eight .banner-content ul li:last-child {
  margin: 0px;
}
.banner-nine .banner-item {
  background-image: url(/assets/images/banner/banner_9.01.jpg);
}
.banner-nine .banner-item.slide-two {
  background-image: url(/assets/images/banner/banner_9.02.jpg);
}
.banner-nine .banner-item.slide-three {
  background-image: url(/assets/images/banner/banner_9.03.jpg);
}
.banner-nine .swiper-pagination {
  max-width: 1140px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: left;
}
.register {
  background-color: #ffb400;
  overflow: hidden;
}
.register .reg-content {
  float: left;
  padding: 31px 0px;
}
.register .reg-button {
  float: right;
  padding: 45px 0px;
}
.register h3 {
  font-size: 36px;
  color: #fff;
}
.register p {
  font-size: 18px;
  color: #fff;
  margin: 0px;
}
.register a {
  background-color: #fff;
  color: #ffb400;
}
.register a:hover,
.register a:focus {
  background-color: #6c5ebf;
  color: #fff;
}
