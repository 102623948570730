.error-page {
  text-align: center;
  font-family: fa-style-family;
}
.error-page .error-content {
  text-align: center;
}
.error-page .error-content h2 {
  font-style: normal;
  font-size: 200px;
  line-height: 200px;
  color: #ffb400;
}
.error-page .error-content h3 {
  font-size: 40px;
  color: #6c5ebf;
}
.error-page .error-content p {
  color: #212121;
  font-size: 18px;
  line-height: 30px;
}
.error-page .error-icon {
  text-align: center;
  line-height: 1;
}
.error-page .error-icon span:before {
  font-size: 370px;
  color: #6c5ebf;
}
.error-page .error-icon {
}
