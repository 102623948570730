.teachers {
  padding: 120px 0px 90px;
}
.teachers .teacher-item {
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #ffffff;
  margin-bottom: 30px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.teachers .teacher-item:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.teachers .teacher-item .teacher-image img {
  width: 100%;
}
.teachers .teacher-item .teacher-content {
  padding: 20px;
}
.teachers .teacher-item .teacher-content h4 a {
  font-size: 24px;
  color: #212121;
  text-decoration: none;
}
.teachers .teacher-item .teacher-content h4 a:hover {
  color: #ffb400;
}
.teachers .teacher-item .teacher-content span {
  font-size: 16px;
  color: #b1b1b1;
}
.teachers .teacher-item .teacher-content .teacher-share {
  margin: 0px;
}
.teachers .teacher-item .teacher-content .teacher-share li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
}
.teachers .teacher-item .teacher-content .teacher-share li a {
  font-size: 20px;
}
.teachers .teacher-item .teacher-content .teacher-share li:first-child a {
  color: #3b5998;
}
.teachers .teacher-item .teacher-content .teacher-share li:nth-child(2) a {
  color: #db4437;
}
.teachers .teacher-item .teacher-content .teacher-share li:nth-child(3) a {
  color: #e94c89;
}
.teachers .teacher-item .teacher-content .teacher-share li:nth-child(4) a {
  color: #23b6ea;
}
.teachers .teacher-item .teacher-content .teacher-share li:nth-child(5) a {
  color: #cb1f27;
}
.teachers-two {
  background-image: url(/assets/images/background/teacher-bg.jpg);
  background-size: cover;
  background-position: center;
}
