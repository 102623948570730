.event {
  padding: 120px 0px 90px;
}
.event .event-item {
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 30px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.event .event-item:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.event .event-item .event-image {
  position: relative;
}
.event .event-item .event-image .date {
  background-color: #6c5ebf;
  height: 70px;
  width: 70px;
  border-radius: 2px;
  text-align: center;
  padding: 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.event .event-item:hover .event-image .date {
  background-color: #ffb400;
}
.event .event-item .event-image .date span {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  line-height: 26px;
}
.event .event-item .event-image .date p {
  font-size: 14px;
  color: #fff;
  line-height: 18px;
  margin: 0px;
}
.event .event-item .event-image img {
  width: 100%;
}
.event .event-item .event-content {
  padding: 25px;
}
.event .event-item .event-content h4 {
  color: #212121;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.event .event-item:hover .event-content h4 {
  color: #ffb400;
}
.event .event-item .event-content ul {
  margin-bottom: 10px;
}
.event .event-item .event-content ul li {
  list-style: none;
  color: #6c5ebf;
}
.event .event-item .event-content ul li:last-child {
  color: #ffb400;
}
.event .event-item .event-content ul li span {
  margin-right: 5px;
}
.event .event-item .event-content p {
  margin-bottom: 22px;
}
.event .event-item .event-content a {
  padding: 8px 26px;
  background-color: #6c5ebf;
}
.event .event-item .event-content a:hover {
  background-color: #ffb400;
}
.event .event-item-list .event-image {
  float: left;
}
.event .event-item-list .event-content {
  display: table;
  padding: 27px 25px;
}
.event-single-item .event-image {
  margin-bottom: 30px;
}
.event-single-item .event-content h3 {
  font-size: 30px;
  color: #212121;
}
.event-single-item .event-content .post-meta {
  margin-bottom: 20px;
}
.event-single-item .event-content .post-meta li {
  list-style: none;
  display: inline-block;
  color: #6c5ebf;
}
.event-single-item .event-content .post-meta li:last-child {
  color: #ffb400;
}
.event-single-item .event-content .post-meta li span {
  margin-right: 5px;
}
.event-single-item .event-counter {
  background-color: #f8f8f8;
  border: 1px solid #f0f0f0;
  padding: 30px;
  overflow: hidden;
  margin-bottom: 25px;
}
.event-single-item .event-counter .clock-item {
  display: inline-block;
  margin-right: 25px;
  float: left;
}
.event-single-item .event-counter span {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #ffb400;
  float: left;
  margin-right: 5px;
}
.event-single-item .event-counter p {
  font-size: 18px;
  color: #696969;
  float: left;
  margin: 20px 0px 0px;
}
.event-single-item .event-counter button {
  float: right;
  padding: 14px 27px;
  outline: none;
  background-color: #6c5ebf;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.event-single-item .event-counter button:hover,
.event-single-item .event-counter button:focus {
  background-color: #ffb400;
}
.event-single-item .event-content h4 {
  color: #6c5ebf;
  margin-bottom: 5px;
}
.event-single-item .event-features {
  margin-top: 10px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}
.event-single-item .event-features .title {
  text-align: center;
  background-color: #ffb400;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.event-single-item .event-features li {
  list-style: none;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
  color: #696969;
  padding: 10px 25px;
}
.event-single-item .event-features li:first-child,
.event-single-item .event-features li:last-child {
  border: none;
}
.event-single-item .event-features li i {
  color: #ffb400;
  margin-right: 10px;
}
.event-single-item .event-features li span {
  float: right;
}
.event-single-item .event-schedule li {
  list-style: none;
  margin-bottom: 5px;
}
.event-single-item .event-schedule li span {
  margin-right: 4px;
  color: #ffb400;
}
.event-single-item .event-map {
  height: 400px;
  width: 100%;
  margin-bottom: 40px;
}
