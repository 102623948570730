/*
    Flaticon icon font: Flaticon
    Creation date: 03/05/2017 10:32
    */

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
    url("./Flaticon.woff") format("woff"),
    url("./Flaticon.ttf") format("truetype"),
    url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-html5:before {
  content: "\f100";
}
.flaticon-quote-sign:before {
  content: "\f101";
}
.flaticon-left-quote:before {
  content: "\f102";
}
.flaticon-cup:before {
  content: "\f103";
}
.flaticon-medicine:before {
  content: "\f104";
}
.flaticon-hourglass:before {
  content: "\f105";
}
.flaticon-buildings:before {
  content: "\f106";
}
.flaticon-bus:before {
  content: "\f107";
}
.flaticon-backpack:before {
  content: "\f108";
}
.flaticon-light-bulb:before {
  content: "\f109";
}
.flaticon-graduation-owl:before {
  content: "\f10a";
}
.flaticon-music-player:before {
  content: "\f10b";
}
.flaticon-flasks:before {
  content: "\f10c";
}
.flaticon-ereader:before {
  content: "\f10d";
}
.flaticon-write-board:before {
  content: "\f10e";
}
.flaticon-jquery-sketch-symbol:before {
  content: "\f10f";
}
.flaticon-open-book-top-view:before {
  content: "\f110";
}
.flaticon-fax:before {
  content: "\f111";
}
.flaticon-placeholder-outline:before {
  content: "\f112";
}
.flaticon-drawing:before {
  content: "\f113";
}
.flaticon-jewel:before {
  content: "\f114";
}
.flaticon-joomla-logo:before {
  content: "\f115";
}
.flaticon-tutorial:before {
  content: "\f116";
}
.flaticon-wordpress-logo:before {
  content: "\f117";
}
.flaticon-css-3-logo:before {
  content: "\f118";
}
.flaticon-php:before {
  content: "\f119";
}
.flaticon-squares-1:before {
  content: "\f11a";
}
.flaticon-squares:before {
  content: "\f11b";
}
.flaticon-adobe-photoshop:before {
  content: "\f11c";
}
.flaticon-book:before {
  content: "\f11d";
}
.flaticon-tags-outline:before {
  content: "\f11e";
}
.flaticon-share:before {
  content: "\f11f";
}
.flaticon-pen:before {
  content: "\f120";
}
.flaticon-signs:before {
  content: "\f121";
}
.flaticon-search:before {
  content: "\f122";
}
.flaticon-shopping-cart:before {
  content: "\f123";
}
.flaticon-like-1:before {
  content: "\f124";
}
.flaticon-back:before {
  content: "\f125";
}
.flaticon-message:before {
  content: "\f126";
}
.flaticon-smartphone:before {
  content: "\f127";
}
.flaticon-chat:before {
  content: "\f128";
}
.flaticon-calendar:before {
  content: "\f129";
}
.flaticon-like:before {
  content: "\f12a";
}
.flaticon-expand:before {
  content: "\f12b";
}
.flaticon-people:before {
  content: "\f12c";
}
.flaticon-phone-call:before {
  content: "\f12d";
}
.flaticon-student:before {
  content: "\f12e";
}
.flaticon-symbols:before {
  content: "\f12f";
}
.flaticon-line-chart:before {
  content: "\f130";
}
.flaticon-world:before {
  content: "\f131";
}

$font-Flaticon-html5: "\f100";
$font-Flaticon-quote-sign: "\f101";
$font-Flaticon-left-quote: "\f102";
$font-Flaticon-cup: "\f103";
$font-Flaticon-medicine: "\f104";
$font-Flaticon-hourglass: "\f105";
$font-Flaticon-buildings: "\f106";
$font-Flaticon-bus: "\f107";
$font-Flaticon-backpack: "\f108";
$font-Flaticon-light-bulb: "\f109";
$font-Flaticon-graduation-owl: "\f10a";
$font-Flaticon-music-player: "\f10b";
$font-Flaticon-flasks: "\f10c";
$font-Flaticon-ereader: "\f10d";
$font-Flaticon-write-board: "\f10e";
$font-Flaticon-jquery-sketch-symbol: "\f10f";
$font-Flaticon-open-book-top-view: "\f110";
$font-Flaticon-fax: "\f111";
$font-Flaticon-placeholder-outline: "\f112";
$font-Flaticon-drawing: "\f113";
$font-Flaticon-jewel: "\f114";
$font-Flaticon-joomla-logo: "\f115";
$font-Flaticon-tutorial: "\f116";
$font-Flaticon-wordpress-logo: "\f117";
$font-Flaticon-css-3-logo: "\f118";
$font-Flaticon-php: "\f119";
$font-Flaticon-squares-1: "\f11a";
$font-Flaticon-squares: "\f11b";
$font-Flaticon-adobe-photoshop: "\f11c";
$font-Flaticon-book: "\f11d";
$font-Flaticon-tags-outline: "\f11e";
$font-Flaticon-share: "\f11f";
$font-Flaticon-pen: "\f120";
$font-Flaticon-signs: "\f121";
$font-Flaticon-search: "\f122";
$font-Flaticon-shopping-cart: "\f123";
$font-Flaticon-like-1: "\f124";
$font-Flaticon-back: "\f125";
$font-Flaticon-message: "\f126";
$font-Flaticon-smartphone: "\f127";
$font-Flaticon-chat: "\f128";
$font-Flaticon-calendar: "\f129";
$font-Flaticon-like: "\f12a";
$font-Flaticon-expand: "\f12b";
$font-Flaticon-people: "\f12c";
$font-Flaticon-phone-call: "\f12d";
$font-Flaticon-student: "\f12e";
$font-Flaticon-symbols: "\f12f";
$font-Flaticon-line-chart: "\f130";
$font-Flaticon-world: "\f131";
