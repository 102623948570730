header {
  position: sticky;
  top: 0;
  z-index: 100;
}
.header-top {
  background-color: #f49d1a;
  padding: 0.55rem;
  overflow: hidden;
  font-size: 1rem 0.5rem;
  font-family: fa-style-family;
}
.header_link {
  color: white;
  text-decoration: none;
}
.main-menu {
  position: relative;
  background-color: #fff;
  z-index: 9;
  padding: 0.5rem;
  font-family: fa-style-family;
}
.header-auth {
  background-color: #f49d1a;
  font-size: 1rem 0.7rem;
  font-family: fa-style-family;
}
